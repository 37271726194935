import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function Settings() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [timetableTitle, setTimetableTitle] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/settings/`)
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const setFormData = (data) => {
    data.map((item) => {
      if (item.key === 'timetableTitle') {
        setTimetableTitle(item.value);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    /* validate first */
    if (timetableTitle.length === 0) {
      toast.error('Timetable title is required');
      return;
    }

    // prepare data
    const data = [
      {
        key: 'timetableTitle',
        value: timetableTitle,
      },
    ];

    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/settings`,
        { data },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
      })
      .catch((err) => console.log(err));

    showLoader(false);
  };

  const back = () => {
    navigate('/settings');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Settings
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="timetableTitle"
          label="Timetable Title"
          variant="outlined"
          value={timetableTitle}
          onChange={(e) => setTimetableTitle(e.target.value)}
          style={{ width: '100%' }}
        />
        <ButtonGroup variant="contained" aria-label="contained primary button group">
          <Button type="submit" color="primary" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
          <Button onClick={back} color="error" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Back
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default Settings;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
