import { Button, ButtonGroup, Card, Container, Stack, Typography } from '@mui/material';
// import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../api';

function ChildDetails() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [parentName, setParentName] = useState('');
  const [email, setEmail] = useState('');
  const [childName, setChildName] = useState('');
  const [school, setSchool] = useState('');
  const [group, setGroup] = useState('');
  const [information, setInformation] = useState('');

  useEffect(() => {
    fetch(`${API_URL}/api/child/${id}`,{
      method: "GET",
    })
    .then((response) => response.json())
    .then((result) => {
        setParentName(result?.parentName);
        setEmail(result?.email);
        setChildName(result?.childName);
        setSchool(result?.school);
        setGroup(result?.group);
        setInformation(result?.information);
      })
    .catch((error) => console.log("error", error));
  }, [id])

  const back = (e) => {
    e.preventDefault();
    navigate('/childProfile');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{fontFamily:"Open Sans", fontWeight:'600'}}>
          Details
        </Typography>
      </Stack>
      <Card sx={{p:4, display: 'flex', flexDirection: 'column',borderRadius: '12px'}}>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px',}}>Parents Name: <span style={{fontWeight:'400', fontSize:'17px'}}> {parentName}</span> </Typography>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px',}}>Email: <span style={{fontWeight:'400', fontSize:'17px'}}> {email}</span> </Typography>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px',}}>Child Name: <span style={{fontWeight:'400', fontSize:'17px'}}> {childName}</span> </Typography>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px',}}>Current School: <span style={{fontWeight:'400', fontSize:'17px'}}> {school}</span> </Typography>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px',}}>Year Group: <span style={{fontWeight:'400', fontSize:'17px'}}> {group}</span> </Typography>
        <Typography variant='h6' sx={{fontFamily:"Open Sans", fontWeight:'500', margin: '3px',padding:'8px', border:'1px solid whitesmoke', borderRadius:'10px', minHeight:'140px'}}>Additional Information: <span style={{fontWeight:'400', fontSize:'17px'}}> {information}</span> </Typography>
  
      </Card>
      <ButtonGroup aria-label="outlined primary button group">
          <Button style={{marginTop:'20px', minWidth:'90px'}}  onClick={(e) => back(e)}>Back</Button>
      </ButtonGroup>
    </Container>
  );
}

export default ChildDetails;

// const useStyles = makeStyles((theme) =>
//   createStyles({
//   })
// );
