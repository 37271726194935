import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, ClickAwayListener, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function NewFAQ() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [testi, setTesti] = useState({
    question: '',
    answer: '',
    subjectStatus: localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english"

  });
  const [open, setOpen] = useState(false);

  const createFaq = async (testi) => {
    showLoader(true);
    await axios
      .post(`${API_URL}/api/FAQ`, testi, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        navigate('/FAQ');
        toast.success(res.data?.message || 'Data added successfully.');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (testi.question === '') {
      toast.error('Question is required');
      return;
    }
    if (testi.answer === '') {
      toast.error('Answer is required');
      return;
    }
    createFaq(testi);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/FAQ');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Add New FAQ
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={testi.question}
          onChange={(e) => setTesti({ ...testi, question: e.target.value })}
          label="Question"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={testi.answer}
          onChange={(e) => setTesti({ ...testi, answer: e.target.value })}
          label="Answer"
          type="text"
          margin="normal"
        />

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewFAQ;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },

    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
