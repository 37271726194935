import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  imageListClasses,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import FileBase from 'react-file-base64';
import 'quill/dist/quill.snow.css';
import { SketchPicker } from 'react-color';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import axios from 'axios';
import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import { Fragment } from 'react';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function NewClasses() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [bookinglink, setBookinglink] = useState('');
  const [firstTermClass, setFirstTermClass] = useState('');
  const [termPrice, setTermPrice] = useState('');

  const [title, setTitle] = useState('');
  const [classHours, setclassHours] = useState([{ days: '', time: '' }]);
  const [pricing, setPricing] = useState([{ classname: '', price: '' }]);
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');
  const [background, setBackground] = useState('#FF5733');
  const [background2, setBackground2] = useState('');
  const [img, setImg] = useState('');
  const [subjectStatus, setSubjectStatus] = useState(localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english")
  const navigate = useNavigate();

  const handleclassHoursChange = (i, e) => {
    const newHourValues = [...classHours];
    newHourValues[i][e.target.name] = e.target.value;
    setclassHours(newHourValues);
  };

  const handlePriceChange = (i, e) => {
    const newPricingValues = [...pricing];
    newPricingValues[i][e.target.name] = e.target.value;
    setPricing(newPricingValues);
  };

  const addclassHoursFields = () => {
    setclassHours([...classHours, { days: '', time: '' }]);
  };

  const addPricingFields = () => {
    setPricing([...pricing, { classname: '', price: '' }]);
  };

  const removeclassHoursFields = (i) => {
    const newHourValues = [...classHours];
    newHourValues?.splice(i, 1);
    setclassHours(newHourValues);
  };

  const removePricingFields = (i) => {
    const newPricingValues = [...pricing];
    newPricingValues?.splice(i, 1);
    setPricing(newPricingValues);
  };

  const createClass = async ({ title, classHours, pricing, description, details, background, img, bookinglink, firstTermClass, termPrice, subjectStatus }) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/classes`,
        { title, classHours, pricing, description, details, background, img, bookinglink, firstTermClass, termPrice, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/class');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* validate first */
    if (title.length === 0) {
      toast.error('Title is required');
      return;
    }
    if (classHours[0].days === '' || classHours[0].time === '') {
      toast.error('Class hours is required');
      return;
    }
    if (pricing[0].days === '' || pricing[0].time === '') {
      toast.error('pricing is required');
      return;
    }
    if (description.length === 0) {
      toast.error('Front description is required');
      return;
    }
    if (details.length === 0) {
      toast.error('Description is required');
      return;
    }
    if (img.length === 0) {
      toast.error('Image is required');
      return;
    }

    createClass({ title, classHours, pricing, description, details, background, img, bookinglink, firstTermClass, termPrice, subjectStatus });
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/class');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Class
        </Typography>
      </Stack>

      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        {/* <InputLabel id="demo-simple-select-label">Subject</InputLabel> */}

        <>
          <TextField
            className={classes.formFields}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            label="Title"
            type="text"
            margin="normal"
          // style={{ width: '82%' }}
          />
          <TextField
            className={classes.formFields}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            value={bookinglink}
            onChange={(e) => setBookinglink(e.target.value)}
            label="Booking Link"
            type="text"
            margin="normal"
          />
        </>
        <>
          <TextField
            className={classes.formFields}
            onChange={(e) => setFirstTermClass(e.target.value)}
            value={firstTermClass}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            label="First Term for Tution class B"
            type="text"
            margin="normal"
          // style={{ width: '82%' }}
          />
          <TextField
            className={classes.formFields}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            value={termPrice}
            onChange={(e) => setTermPrice(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
            }}
            label="Price"
            type="text"
            margin="normal"
          />
        </>
        <br />
        <>
          {classHours?.map((element, index) => (
            <Fragment key={index}>
              <TextField
                // className={classes.formFields}
                onChange={(e) => handleclassHoursChange(index, e)}
                value={element?.days}
                name="days"
                label="Day"
                type="text"
                margin="normal"
                style={{ width: '40%' }}
              />
              <TextField
                // className={classes.formFields}
                onChange={(e) => handleclassHoursChange(index, e)}
                value={element?.time || ''}
                label="Time"
                name="time"
                type="text"
                margin="normal"
                style={{ width: '40%' }}
              />
              {index ? (
                <Button variant="outlined" color="error" onClick={() => removeclassHoursFields(index)}>
                  <RemoveRoundedIcon />
                </Button>
              ) : null}
            </Fragment>
          ))}
          <br />
          <Button variant="contained" onClick={() => addclassHoursFields()}>
            <AddRoundedIcon />
          </Button>
        </>
        <br />
        <>
          {pricing?.map((element, index) => (
            <Fragment key={index}>
              <TextField
                // className={classes.formFields}
                onChange={(e) => handlePriceChange(index, e)}
                value={element.classname || ''}
                label="Class Type"
                type="text"
                name="classname"
                margin="normal"
                style={{ width: '40%' }}
              />
              <TextField
                // className={classes.formFields}
                onChange={(e) => handlePriceChange(index, e)}
                value={element.price || ''}
                label="Price"
                name="price"
                type="text"
                margin="normal"
                style={{ width: '40%' }}
              />
              {index ? (
                <Button variant="outlined" color="error" onClick={() => removePricingFields(index)}>
                  <RemoveRoundedIcon />
                </Button>
              ) : null}
            </Fragment>
          ))}
          <br />
          <Button variant="contained" onClick={() => addPricingFields()}>
            <AddRoundedIcon />
          </Button>
        </>
        <br />

        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Front Description</p>
          <QuillEditor value={description} setDesc={setDescription} />
        </div>
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Description</p>
          <QuillEditor value={details} setDesc={setDetails} />
        </div>
        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setImg(base64)}
            />
          </div>
        </div>

        <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Change Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(background);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setBackground(background2);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: background }} />
            </Button>
          )}
        </div>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewClasses;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    nestedField: {
      flexBasis: '',
    },
    textEditor: {
      flexBasis: '91.5%',
      // margin: "10px",
      // padding: "10px",
      // minHeight: "200px",
      // borderRadius: "4px",
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
