import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function UpdateTimetable() {
  const classes = useStyles();
  const { id } = useParams();
  const [tutoringClass, setTutoringClass] = useState('');
  const [day, setDay] = useState('');
  const [time, setTime] = useState('');
  const [subjectStatus, setSubjectStatus] = useState("english")

  const navigate = useNavigate();

  const updateTimetable = async (tutoringClass, day, time, subjectStatus) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/timetable/${id}`,
        { tutoringClass, day, time, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/timetable');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/timetable/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setTutoringClass(result?.tutoringClass);
        setDay(result?.day);
        setTime(result?.time);
        setSubjectStatus(result?.subjectStatus);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tutoringClass === '') {
      toast.error('Tutoring class is required');
      return;
    }

    if (day === '') {
      toast.error('Day is required');
      return;
    }
    if (time === '') {
      toast.error('Time is required');
      return;
    }
    updateTimetable(tutoringClass, day, time, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/timetable');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Timetable
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={tutoringClass}
          onChange={(e) => setTutoringClass(e.target.value)}
          label="Tutoring Class"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={day}
          onChange={(e) => setDay(e.target.value)}
          label="Day"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          label="Time"
          type="text"
          margin="normal"
        />
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateTimetable;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
