import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import Select from '@mui/material/Select';

// sections
import { CourseListHead, CourseListToolbar } from '../../sections/@dashboard/course';
// mock
import { API_URL } from '../../api';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  Editing,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  RowDragging,
  Scrolling,
  SearchPanel,
  Button as DevBtn,
} from 'devextreme-react/data-grid';
import { toast } from 'react-toastify';
import { DeleteModal } from './DeleteModal';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'date', label: 'Course Date', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'fee', label: 'Fee', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },

  { id: 'bookingLink', label: 'Booking Link', alignRight: false },
  { id: '', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_course) => _course.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CoursePage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [plus, setPlus] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [subjectStatus, setSubjectStatus] = useState("english")

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  // const deleteCourse = (id) => {
  //   axios.delete(`${API_URL}/api/courses/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/courses/`)
  //       .then((res) => setCourses(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    let subject = localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english"
    getListCourrse(subject)
  }, [plus]);
  const getListCourrse = (parm) => {
    axios
      .get(`${API_URL}/api/courses?subject=${parm}`)
      .then((res) => {
        setCourses([...res.data]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }
  const handleOpenMenu = (event, rowId) => {
    setOpen(event.currentTarget);
    setCurrentId(rowId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = courses.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - courses?.length) : 0;

  const filteredCourses = applySortFilter(courses, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCourses.length && !!filterName;

  const createNew = (e) => {
    e.preventDefault();
    navigate('/course/newCourse');
  };

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...courses];

    const toIndex = newData.findIndex((item) => item._id === visibleRows[e.toIndex].data._id);
    const fromIndex = newData.findIndex((item) => item._id === e.itemData._id);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    await changePriority(newData.map((i) => i._id));
    setCourses(newData);
  };

  const changePriority = async (data) => {
    return axios
      .post(
        `${API_URL}/api/courses/priority`,
        { data: data },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        // getAllCourseCategoryData();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Something went wrong. Please try again later!');
      });
  };

  return (
    <>
      <Helmet>
        <title> Courses | English Teacher </title>
      </Helmet>

      <Container>
        <Stack className="stacky">
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Courses
          </Typography>
          <div>
            <Button
              onClick={(e) => createNew(e)}
              variant="contained"
              style={{ padding: '8px 22px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add a New Course
            </Button>
          </div>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          {/* <CourseListToolbar
            selected={selected}
            setCourses={setCourses}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          {/* <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CourseListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={courses?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableBody>
                    {filteredCourses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { _id, title, date, time, cost, location, bookinglink } = row;
                      const selectedCourse = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedCourse}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              size="medium"
                              checked={selectedCourse}
                              onChange={(event) => handleClick(event, _id)}
                              sx={{ mr: 4 }}
                            />
                          </TableCell>

                          <TableCell sx={{ fontFamily: 'Open Sans', fontWeight: '600' }} align="left">
                            {title}
                          </TableCell>

                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}>
                            {date}
                          </TableCell>

                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}>
                            {time}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}>
                            {cost}
                          </TableCell>
                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}>
                            {location}
                          </TableCell>

                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}>
                            {bookinglink}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>

                          <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                  fontFamily: 'Open Sans',
                                },
                              },
                            }}
                          >
                            <Link to={`/course/updateCourse/${currentId}`}>
                              <MenuItem sx={{ fontFamily: 'Open Sans' }}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                            </Link>

                            <MenuItem
                              onClick={() => deleteCourse(currentId)}
                              sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph fontFamily="Open Sans">
                            Not found
                          </Typography>

                          <Typography variant="body2" fontFamily="Open Sans">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar> */}

          <DataGrid dataSource={courses} showBorders={true} autoExpandAll={true} columnAutoWidth={true} keyExpr="_id">
            <LoadPanel enabled={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={{ of: window, at: 'top', my: 'top', offset: { y: 10 } }} />
            <Scrolling mode="standard" />
            <Editing mode="row" allowDeleting={true} allowUpdating={true} />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              showPageSizeSelector={true}
              displayMode="full"
              showInfo={true}
              showNavigationButtons={true}
            />
            <RowDragging allowReordering={true} onReorder={onReorder} showDragIcons={true} />
            <Column type="buttons" caption="Action" width={200}>
              <DevBtn onClick={(e) => navigate(`/course/updateCourse/${e.row?.data?._id}`)} icon="trash">
                <Button className="btn">
                  <Tooltip title="Edit" placement="top-start" arrow>
                    <Iconify icon="mdi:pencil" />
                  </Tooltip>
                </Button>
              </DevBtn>
              {/* <DevBtn onClick={(e) => deleteCourse(e.row?.data?._id)} icon="info">
                <Button className="btn">
                  <Iconify icon="mdi:trash-outline" />
                </Button>
              </DevBtn> */}
              <DevBtn
                icon="delete"
                onClick={(e) => {
                  handleDeleteModal(e?.row?.data?._id);
                }}
              >
                <Button className="btn">
                  <Tooltip title="Delete" placement="top-start" arrow>
                    <Iconify icon="mdi:trash-outline" />
                  </Tooltip>
                </Button>
              </DevBtn>
            </Column>

            <Column dataField="title" caption="Title" />
            <Column dataField="date" caption="Date" />
            <Column dataField="time" caption="Time" />
            <Column dataField="cost" caption="Cost" />
            <Column dataField="location" caption="Location" />
            <Column dataField="bookinglink" caption="bookinglink" />
          </DataGrid>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={courses?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setCourses={setCourses}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}
