import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Modal,
  TextField,
  ButtonGroup,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { TimetableListHead, TimetableListToolbar } from '../../sections/@dashboard/timetable';
// mock
import { API_URL } from '../../api';
import { toast } from 'react-toastify';
import { DataGrid, LoadPanel } from 'devextreme-react';
import {
  Column,
  Editing,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  RowDragging,
  Scrolling,
  SearchPanel,
  Button as DevBtn,
} from 'devextreme-react/data-grid';
import { DeleteModal } from './DeleteModal';
import Cookies from 'universal-cookie';
// import { async } from 'q';
import showLoader from 'src/components/loader';
const cookies = new Cookies();
// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: 'title', label: 'Tutoring Class', alignRight: false },
  { id: 'day', label: 'Day', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: '', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = Array.isArray && array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_timetable) => _timetable?.title?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function TimetablePage() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [timetable, setTimetable] = useState();
  const [currentId, setCurrentId] = useState(null);
  const [plus, setPlus] = useState(0);
  const [titleData, setTitleData] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [description, setDescription] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  // const deletetimetable = (id) => {
  //   axios.delete(`${API_URL}/api/timetable/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/timetable/`)
  //       .then((res) => setTimetable(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/join-team`)
      .then((res) => {
        setTimetable(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [plus]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/join-team-title`)
      .then((res) => {
        setTitleData(res.data);
        if (res.data) {
          setPageTitle(res.data?.title);
          setDescription(res.data?.description);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleOpenMenu = (event, rowId) => {
    setOpen(event.currentTarget);
    setCurrentId(rowId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = timetable?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - timetable?.length) : 0;

  const filteredTimetables = applySortFilter(timetable, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredTimetables?.length && !!filterName;

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...timetable];

    const toIndex = newData.findIndex((item) => item._id === visibleRows[e.toIndex].data._id);
    const fromIndex = newData.findIndex((item) => item._id === e.itemData._id);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    await changePriority(newData.map((i) => i._id));
    setTimetable(newData);
  };

  const changePriority = async (data) => {
    return axios
      .post(
        `${API_URL}/api/timetable/priority`,
        { data: data },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        // getAllCourseCategoryData();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Something went wrong. Please try again later!');
      });
  };

  const createPageTitle = async (data) => {
    showLoader(true);
    await axios
      .post(`${API_URL}/api/join-team-title`, data, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        handleModal();
        return toast.success(res.data?.message || 'Data added successfully.');
      })
      .catch((err) => {
        handleModal();
        return toast.error('Something went wrong. Please try again later.');
      });
    showLoader(false);
  };

  const updatePageTitle = async (data) => {
    showLoader(true);
    await axios
      .post(`${API_URL}/api/join-team-title/${data._id}`, data, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        handleModal();
        return toast.success(res.data?.message || 'Data updated successfully.');
      })
      .catch((err) => {
        handleModal();
        return toast.error('Something went wrong. Please try again later.');
      });
    showLoader(false);
  };

  const handleSubmitTitle = (e) => {
    e.preventDefault();
    if (pageTitle === '') {
      toast.error('Title is required');
      return;
    }
    if (description === '') {
      toast.error('Sub Title is required');
      return;
    }
    if (titleData?.length > 0 && titleData[0]?._id) {
      return updatePageTitle({ title: pageTitle, description: description, _id: titleData[0]?._id });
    }
    return createPageTitle({ title: pageTitle, description: description });
  };

  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <Helmet>
        <title> Timetable | English Teacher </title>
      </Helmet>

      <Container>
        <Stack className="stacky">
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Join The Team
          </Typography>
          <div>
            <Button
              onClick={(e) => handleModal(e)}
              variant="contained"
              style={{ padding: '8px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            >
              Change Title
            </Button>
          </div>
        </Stack>
        <Modal
          open={modal}
          onClose={handleModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
            onSubmit={handleSubmitTitle}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6" component="h2" sx={{ fontFamily: 'Open Sans', fontWeight: '700' }}>
                Join Team Page Title
              </Typography>
            </Stack>
            <TextField
              fullWidth
              value={pageTitle}
              onChange={(e) => setPageTitle(e.target.value)}
              label="Page Title"
              type="text"
              margin="normal"
            />
            {/* <TextField
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
              type="text-area"
              margin="normal"
            /> */}
            <TextField
              fullWidth
              multiline
              rows={6}
              // maxRows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
              type="text"
              margin="normal"
            />
            <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
              <Button onClick={(e) => handleModal(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
                Close
              </Button>
              <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
                Save
              </Button>
            </ButtonGroup>
          </Box>
        </Modal>
        <Card sx={{ borderRadius: '12px' }}>
          <DataGrid dataSource={timetable} showBorders={true} autoExpandAll={true} columnAutoWidth={true} keyExpr="_id">
            <LoadPanel enabled={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={{ of: window, at: 'top', my: 'top', offset: { y: 10 } }} />
            <Scrolling mode="standard" />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              showPageSizeSelector={true}
              displayMode="full"
              showInfo={true}
              showNavigationButtons={true}
            />

            <Column dataField="fullName" caption="Full Name" />
            <Column dataField="email" caption="Email" />
            <Column dataField="telephone" caption="Telephone" />
            <Column dataField="description" caption="Description" />
            <Column cssClass="p-0" type="buttons" caption="CV">
              <DevBtn
                onClick={(e) => window.open(e?.row?.data?.cvFile?.url, '_blank')}
                visible={(e) => (e?.row?.data?.cvFile?.url ? true : false)}
                icon="edit"
              >
                <AttachEmailIcon type="button" />
              </DevBtn>
            </Column>
          </DataGrid>
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setTimetable={setTimetable}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
