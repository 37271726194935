import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function NewEnquiry() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [enq, setEnq] = useState('');

  const createEnq = async (enquiry) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/enquiry`,
        { enquiry },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/enquiry');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (enq === '') {
      toast.error('Enquiry is required');
      return;
    }
    createEnq(enq);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/enquiry');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Enquiry
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={enq}
          onChange={(e) => setEnq(e.target.value)}
          label="Enquiry"
          type="text"
          margin="normal"
        />

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Add
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewEnquiry;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
