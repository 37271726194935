import { Alert, Box, Button, ButtonGroup, Container, Modal, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../api';
import { toast } from 'react-toastify';
import showLoader from 'src/components/loader';

const cookies = new Cookies();

function ChangePassword() {
  const classes = useStyles();
  const navigate = useNavigate();

  const decode = jwtDecode(cookies?.get('TOKEN'));

  const userId = decode?.userId;

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [img, setImage] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const handleClose = () => setModalOpen(false);

  const checkPassword = async (userId, password) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/auth/passwordCheck`,
        { userId, password },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        if (res?.data?.message === 'Password matched') {
          setOpen(true);
        } else {
          setModalOpen(true);
          setError(res?.data?.message);
        }
      });
    showLoader(false);
  };
  const handleCheck = (e) => {
    e.preventDefault();
    if (password === '') {
      toast.error('Password is required');
      return;
    }
    checkPassword(userId, password);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/auth/${userId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setUserName(result?.userName);
        setEmail(result?.email);
        setImage(result?.img);
        setStatus(result?.status);
      })
      .catch((error) => console.log('error', error));
  }, [userId]);

  const updatePassword = async (userName, email, password, img, status) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/auth/updatePassword/${userId}`,
        { userName, email, password, img, status },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        navigate('/class');
      })
      .catch((err) => {
        console.log(err);
      });

    showLoader(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword === '') {
      toast.error('New Password is required');
      return;
    }
    updatePassword(userName, email, newPassword, img, status);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/');
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Change Password
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        {!open ? (
          <>
            <TextField
              className={classes.formFields}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Old Password"
              type="text"
              margin="normal"
            />
            <Button variant="contained" onClick={handleCheck} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
              Check
            </Button>
          </>
        ) : (
          <>
            <TextField
              className={classes.formFields}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label="New Password"
              type="text"
              margin="normal"
            />
          </>
        )}
        {modalOpen ? (
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Alert severity="error">{error}</Alert>
            </Box>
          </Modal>
        ) : (
          ''
        )}
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Update
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default ChangePassword;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
