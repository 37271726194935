import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function NewTimetable() {
  const classes = useStyles();
  const [timetable, setTimetable] = useState({ tutoringClass: '', day: '', time: '', subjectStatus: localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english" });

  const navigate = useNavigate();

  const createTimetable = async (timetable) => {
    showLoader(true);
    await axios
      .post(`${API_URL}/api/timetable`, timetable, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/timetable');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (timetable.tutoringClass === '') {
      toast.error('Tutoring class is required');
      return;
    }

    if (timetable.day === '') {
      toast.error('Day is required');
      return;
    }
    if (timetable.time === '') {
      toast.error('Time is required');
      return;
    }
    createTimetable(timetable);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/timetable');
  };
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Timetable
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={timetable.tutoringClass}
          onChange={(e) => setTimetable({ ...timetable, tutoringClass: e.target.value })}
          label="Tutoring Class"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={timetable.day}
          onChange={(e) => setTimetable({ ...timetable, day: e.target.value })}
          label="Day"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={timetable.time}
          onChange={(e) => setTimetable({ ...timetable, time: e.target.value })}
          label="Time"
          type="text"
          margin="normal"
        />
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewTimetable;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
