import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { API_URL } from '../api/index';
// ----------------------------------------------------------------------
import avatarDefault from '../assets/images/avatars/avatarDefault.jpg';
// ----------------------------------------------------------------------
const cookies = new Cookies();

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(avatarDefault);

  const decode = jwtDecode(cookies?.get('TOKEN'));

  useEffect(() => {
    fetch(`${API_URL}/api/auth/${decode?.userId}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setProfilePic(result?.img);
      })
      .catch((error) => console.log('error', error));
  }, [decode?.userId]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    navigate('/class');
    setOpen(null);
  };

  const handlePassword = () => {
    navigate('/changePassword');
    setOpen(null);
  };

  const handleLogout = () => {
    cookies.remove('TOKEN', { path: '/' });
    localStorage.removeItem("subject")
    window.location.href = '/';
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={profilePic} alt={decode?.userName} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap sx={{ fontWeight: '600' }}>
            {decode?.userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {decode?.userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleClose}>Home</MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handlePassword} sx={{ m: 1 }}>
          Change Password
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
