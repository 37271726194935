import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// mock
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();
export const DeleteModal = ({ deleteModal, handleDeleteModal, deleteId, setEnq, setPlus, plus }) => {
  // ** Function to delete single role
  const deleteEnq = async (id) => {
    showLoader(true);
    await axios
      .delete(`${API_URL}/api/enquiry/${id}`, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then(
        (res) => {
          toast.success(res.data?.message || 'Data deleted successfully.');
          setPlus(plus + 1);
        },
        axios
          .get(`${API_URL}/api/enquiry/`)
          .then((res) => {
            handleDeleteModal();
            setEnq(res?.data);
          })
          .catch((error) => {
            console.log(error);
            handleDeleteModal();
            toast.error(err?.response?.data?.message || 'Something went wrong');
          })
      );
    showLoader(false);
  };

  return (
    <Dialog open={deleteModal}>
      <DialogTitle>Delete Enquiry</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">Are you sure? </Typography>
        <Typography variant="subtitle1">Once deleted data cannot be recovered.</Typography>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={() => {
            deleteEnq(deleteId);
          }}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          type="submit"
          style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
          onClick={handleDeleteModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
