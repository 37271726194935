import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Tooltip,
  Select,
  MenuItem
} from '@mui/material';
import Iconify from '../../components/iconify';
import {
  LoadPanel,
  SearchPanel,
  Paging,
  Pager,
  FilterPanel,
  FilterBuilderPopup,
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Summary,
  GroupItem,
  TotalItem,
  ValueFormat,
  Export,
  Button as DevBtn,
  Lookup,
  RowDragging,
} from 'devextreme-react/data-grid';
import axios from 'axios';
// components
// sections
// mock
import { API_URL } from '../../api';
import Grid from '@mui/material/Grid';
import { DeleteModal } from './DeleteModal';


// import { async } from 'q';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------




export default function Subscriber() {
  const [timetable, setJoinCources] = useState([]);
  const [plus, setPlus] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [subjectStatus, setSubjectStatus] = useState("english")
  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };
  // const deletetimetable = (id) => {
  //   axios.delete(`${API_URL}/api/timetable/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/timetable/`)
  //       .then((res) => setJoinCources(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    let subject = localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english"

    getListPayment(subject)
  }, [plus]);
  const getListPayment = (parm) => {
    axios
      .get(`${API_URL}/api/join-courses?subject=${parm}`)
      .then((res) => {
        setJoinCources(res.data);
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Helmet>
        <title> Payments | English Teacher </title>
      </Helmet>

      <Container>
        <Stack className="stacky">
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Payments List
          </Typography>

        </Stack>


        <Card sx={{ borderRadius: '12px' }}>
          <DataGrid dataSource={timetable} showBorders={true} autoExpandAll={true} columnAutoWidth={true} keyExpr="_id">
            <LoadPanel enabled={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={{ of: window, at: 'top', my: 'top', offset: { y: 10 } }} />
            <Scrolling mode="standard" />
            {/* <Editing mode="row" allowDeleting={true} allowUpdating={true} /> */}
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              showPageSizeSelector={true}
              displayMode="full"
              showInfo={true}
              showNavigationButtons={true}
            />
            <RowDragging allowReordering={true} showDragIcons={true} />
            <Column type="buttons" caption="Action" >
              <DevBtn
                icon="delete"
                onClick={(e) => {
                  handleDeleteModal(e?.row?.data?._id);
                }}
              > <Button className="btn">
                  <Tooltip title="Delete" placement="top-start" arrow>
                    <Iconify icon="mdi:trash-outline" />
                  </Tooltip>
                </Button>
              </DevBtn>
            </Column>
            <Column dataField="course_name" caption="Course Name" />
            <Column dataField="parent_name" caption="Parent Name" />
            <Column dataField="parent_email" caption="Parent Email" />
            <Column dataField="childfull_name" caption="Childfull Name" />
            <Column dataField="shool_name" caption="Shool Name" />
            <Column dataField="plan_price" caption="Plan Price" />

          </DataGrid>

        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setJoinCources={setJoinCources}
          setPlus={setPlus}
          plus={plus}
        />
      </Container >
    </>
  );
}



