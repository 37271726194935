import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Card,
  CircularProgress,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { EnquiryListHead, EnquiryListToolbar } from '../../sections/@dashboard/enquiry';
// mock
import { API_URL } from '../../api';
import { DeleteModal } from './DeleteModal';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'enquiry', label: 'Enquiry', alignRight: false },
  { id: '', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (enq) => enq.enquiry.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EnquiryPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('enquiry');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [enq, setEnq] = useState([]);
  const [currentId, setCurrentId] = useState(null);
  const [plus, setPlus] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  // const deleteEnq = (id) => {
  //   axios.delete(`${API_URL}/api/enquiry/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/enquiry/`)
  //       .then((res) => setEnq(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/enquiry/`)
      .then((res) => {
        setEnq(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [plus]);

  const handleOpenMenu = (event, rowId) => {
    setOpen(event.currentTarget);
    setCurrentId(rowId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = enq?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, enquiry) => {
    const selectedIndex = selected.indexOf(enquiry);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, enquiry);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - enq?.length) : 0;

  const filteredEnquiry = applySortFilter(enq, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredEnquiry.length && !!filterName;

  const createNew = (e) => {
    e.preventDefault();
    navigate('/enquiry/newEnquiry');
  };

  return (
    <>
      <Helmet>
        <title> Enquiry | English Teacher </title>
      </Helmet>
      <Container>
        <Stack className="stacky">
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Enquiry
          </Typography>
          <Button
            onClick={(e) => createNew(e)}
            variant="contained"
            style={{ padding: '8px px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add a New Enquiry
          </Button>
        </Stack>
        <Card sx={{ borderRadius: '12px' }}>
          <EnquiryListToolbar
            selected={selected}
            setEnq={setEnq}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <EnquiryListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={enq?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableBody>
                    {filteredEnquiry?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                      const { _id, enquiry } = row;
                      const selectedEnquiry = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedEnquiry}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              size="medium"
                              checked={selectedEnquiry}
                              onChange={(event) => handleClick(event, _id)}
                              sx={{ mr: 4 }}
                            />
                          </TableCell>

                          <TableCell align="left" sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
                            {enquiry}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                          <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <Link to={`/enquiry/updateEnquiry/${currentId}`}>
                              <MenuItem style={{ fontFamily: 'Open Sans' }}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                            </Link>
                            {/* <MenuItem
                              onClick={() => deleteEnq(currentId)}
                              sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem> */}
                            <MenuItem
                              onClick={() => handleDeleteModal(currentId)}
                              sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ fontFamily: 'Open Sans' }}>
                            Not found
                          </Typography>

                          <Typography variant="body2" sx={{ fontFamily: 'Open Sans' }}>
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={enq?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setEnq={setEnq}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}
