import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, ClickAwayListener, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function NewResource() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [background, setBackground] = useState('#FF5733');
  const [background2, setBackground2] = useState('#FF5733');
  const [open, setOpen] = useState(false);
  const [subjectStatus, setSubjectStatus] = useState(localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english")

  const createResource = async (title, description, longDescription, background, subjectStatus) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/resources`,
        { title, description, longDescription, background, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/resource');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === '') {
      toast.error('Title is required');
      return;
    }

    if (description === '') {
      toast.error('Front description is required');
      return;
    }

    if (longDescription === '') {
      toast.error('Description is required');
      return;
    }
    createResource(title, description, longDescription, background, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/resource');
  };
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Resource
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label="Title"
          type="text"
          margin="normal"
        />
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Front Description</p>
          <QuillEditor value={description} setDesc={setDescription} />
        </div>
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Description</p>
          <QuillEditor value={longDescription} setDesc={setLongDescription} />
        </div>
        {/* <div className={classes.formFields} style={{ fontFamily: 'Open Sans' }}>
          Choose Color
          {open ?
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <SketchPicker
                color={background}
                onChangeComplete={color => {
                  setBackground(color.hex);
                }}
              />
            </ClickAwayListener>
            :
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: background }} />
            </Button>
          }
        </div> */}

        <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Change Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(background);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setBackground(background2);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: background }} />
            </Button>
          )}
        </div>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewResource;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      // margin: "10px",
      // padding: "10px",
      // height: "200px",
      // border: "1px solid #B3B3B3",
      // borderRadius: "4px",
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
