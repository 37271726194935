import { Button, ButtonGroup, Card, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../api';

function ContactDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [enquiry, setEnquiry] = useState('');
  const [aboutEnquiry, setAboutEnquiry] = useState('');

  useEffect(() => {
    fetch(`${API_URL}/api/messages/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setName(result?.name);
        setEmail(result?.email);
        setPhone(result?.phone);
        setAboutUs(result?.aboutUs);
        setEnquiry(result?.enquiry);
        setAboutEnquiry(result?.aboutEnquiry);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const back = (e) => {
    e.preventDefault();
    navigate('/childProfile');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Details
        </Typography>
      </Stack>
      <Card sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Name: <span style={{ fontWeight: '400', fontSize: '17px' }}> {name}</span>{' '}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Email: <span style={{ fontWeight: '400', fontSize: '17px' }}> {email}</span>{' '}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Phone: <span style={{ fontWeight: '400', fontSize: '17px' }}> {phone}</span>{' '}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          How did you hear about us? <span style={{ fontWeight: '400', fontSize: '17px' }}> {aboutUs}</span>{' '}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
          }}
        >
          Enquiry About: <span style={{ fontWeight: '400', fontSize: '17px' }}> {aboutEnquiry}</span>{' '}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '500',
            margin: '3px',
            padding: '8px',
            border: '1px solid whitesmoke',
            borderRadius: '10px',
            minHeight: '140px',
          }}
        >
          Enquiry: <span style={{ fontWeight: '400', fontSize: '17px' }}> {enquiry}</span>{' '}
        </Typography>
      </Card>
      <ButtonGroup aria-label="outlined primary button group">
        <Button style={{ marginTop: '20px', minWidth: '90px' }} onClick={(e) => back(e)}>
          Back
        </Button>
      </ButtonGroup>
    </Container>
  );
}

export default ContactDetails;
