import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import CollectionsIcon from '@mui/icons-material/Collections';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import ChildCareRoundedIcon from '@mui/icons-material/ChildCareRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import QuizIcon from '@mui/icons-material/Quiz';
import HomeIcon from '@mui/icons-material/Home';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import { Menu, MenuItem } from '@mui/material';

export const mainListItems = (
  <>
    <Link to="/class">
      <ListItemButton>
        <ListItemIcon>
          <ImportContactsRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Classes" />
      </ListItemButton>
    </Link>
    <Link to="/gallery">
      <ListItemButton>
        <ListItemIcon>
          <CollectionsIcon />
        </ListItemIcon>
        <ListItemText primary="Gallery" />
      </ListItemButton>
    </Link>
    <Link to="/course">
      <ListItemButton>
        <ListItemIcon>
          <SchoolRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Courses" />
      </ListItemButton>
    </Link>
    <Link to="/timetable">
      <ListItemButton>
        <ListItemIcon>
          <AccessTimeIcon />
        </ListItemIcon>
        <ListItemText primary="Timetable" />
      </ListItemButton>
    </Link>
    <Link to="/resource">
      <ListItemButton>
        <ListItemIcon>
          <LocalLibraryRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Resources" />
      </ListItemButton>
    </Link>
    <Link to="/testimonial">
      <ListItemButton>
        <ListItemIcon>
          <HandshakeRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Testimonials" />
      </ListItemButton>
    </Link>
    <Link to="/about-us">
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="About Us" />
      </ListItemButton>
    </Link>

    <Link to="/FAQ">
      <ListItemButton>
        <ListItemIcon>
          <QuizIcon />
        </ListItemIcon>
        <ListItemText primary="FAQ" />
      </ListItemButton>
    </Link>

    <Link to="/teacher">
      <ListItemButton>
        <ListItemIcon>
          <Groups2RoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Meet the Team" />
      </ListItemButton>
    </Link>
    {/* <Link to="/contact">
      <ListItemButton>
        <ListItemIcon>
          <AlternateEmailRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Us OLD" />
      </ListItemButton>
    </Link> */}
    <Link to="/childprofile">
      <ListItemButton>
        <ListItemIcon>
          {/* <ForumIcon /> */}
          <AlternateEmailRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Us New" />
      </ListItemButton>
    </Link>
    <Link to="/join-team">
      <ListItemButton>
        <ListItemIcon>
          {/* <ForumIcon /> */}
          <AlternateEmailRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Join the Team" />
      </ListItemButton>
    </Link>
    <Link to="/subscriber">
      <ListItemButton>
        <ListItemIcon>
          {/* <ForumIcon /> */}
          <PaymentsTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Payments" />
      </ListItemButton>
    </Link>
    <Link to="/user">
      <ListItemButton>
        <ListItemIcon>
          <SupervisorAccountRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </Link>
    <Link to="/settings">
      <ListItemButton>
        <ListItemIcon>
          <MiscellaneousServicesIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
    </Link>
  </>
);
