import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function NewFAQDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [FAQDetailData, setFAQDetailData] = useState({
    // bannerText: '',
    faqHead: '',
    faqSubHead: '',
    subjectStatus: "english"
    // bannerColor: '#FF5733',
    // bannerImg: '',
  });
  const [FAQDetailDataMath, setFAQDetailDatamMath] = useState({
    // bannerText: '',
    faqHeadMath: '',
    faqSubHeadMath: '',
    subjectStatus: "math"
    // bannerColor: '#FF5733',
    // bannerImg: '',
  });

  const [background2, setBackground2] = useState('');
  const [open, setOpen] = useState(false);
  // const [loader, setLoader] = useState(false);

  const createFAQDetailData = async (FAQDeta) => {
    debugger
    // setLoader(true);
    showLoader(true);
    await axios
      .post(`${API_URL}/api/FAQ-detail`, FAQDeta, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        navigate('/FAQ');

        toast.success(res.data?.message || 'Data added successfully.');
      })
      .catch((err) => console.log(err));
    // setLoader(false);
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (FAQDetailData.bannerText === '') {
    //   toast.error('Banner text is required');
    //   return;
    // }
    if (FAQDetailData.faqHead === '') {
      toast.error('FAQ Head is required');
      return;
    }
    if (FAQDetailData.faqSubHead === '') {
      toast.error('FAQ Sub Head is required');
      return;
    }
    // if (FAQDetailData.bannerColor === '') {
    //   toast.error('Banner Color is required');
    //   return;
    // }
    // if (FAQDetailData.bannerImg === '') {
    //   toast.error('Banner Img is required');
    //   return;
    // }
    FAQDetailData.subjectStatus = "english"
    createFAQDetailData(FAQDetailData);
  };
  const handleSubmitMath = (e) => {
    e.preventDefault();
    // if (FAQDetailData.bannerText === '') {
    //   toast.error('Banner text is required');
    //   return;
    // }
    if (FAQDetailDataMath.faqHeadMath === '') {
      toast.error('FAQ Head is required');
      return;
    }
    if (FAQDetailDataMath.faqSubHeadMath === '') {
      toast.error('FAQ Sub Head is required');
      return;
    }
    // if (FAQDetailData.bannerColor === '') {
    //   toast.error('Banner Color is required');
    //   return;
    // }
    // if (FAQDetailData.bannerImg === '') {
    //   toast.error('Banner Img is required');
    //   return;
    // }
    let faqMath = {
      faqHead: FAQDetailDataMath.faqHeadMath,
      faqSubHead: FAQDetailDataMath.faqSubHeadMath,
      subjectStatus: "math"
    }
    createFAQDetailData(faqMath);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/FAQ');
  };

  const getBase64FromUrl = async (url) => {
    return new Promise(async (resolve) => {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        return resolve(base64data);
      };
    });
  };
  useEffect(() => {
    fetch(`${API_URL}/api/FAQ-detail`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then(async (result) => {
        if (result.length) {
          result.map((x) => {
            if (x.subjectStatus == "math") {
              let data = {
                // bannerText: result.bannerText,
                faqHeadMath: x.faqHead,
                faqSubHeadMath: x.faqSubHead,
                subjectStatus: x.subjectStatus,
                // bannerColor: result.bannerColor,
              };
              setFAQDetailDatamMath(data);
            }
            else if (x.subjectStatus == "english") {
              let data = {
                // bannerText: result.bannerText,
                faqHead: x.faqHead,
                faqSubHead: x.faqSubHead,
                subjectStatus: x.subjectStatus,
                // bannerColor: result.bannerColor,
              };
              setFAQDetailData(data);
            }
          })
        }
        // if (result?.bannerImg?.url) {
        //   data.bannerImg = await getBase64FromUrl(result?.bannerImg?.url);
        // }
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  return (
    <Container>
      {/* {loader == true ?  */}
      {/* <CircularProgress style={{ zIndex: 1000000, position: 'fixed' }} /> */}
      {/* : ''} */}

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          FAQ Details English
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        {/* <TextField
          className={classes.formFields}
          value={FAQDetailData.bannerText}
          onChange={(e) => setFAQDetailData({ ...FAQDetailData, bannerText: e.target.value })}
          label="Banner Text"
          type="text"
          margin="normal"
        /> */}
        <TextField
          className={classes.formFields}
          value={FAQDetailData.faqHead}
          onChange={(e) => setFAQDetailData({ ...FAQDetailData, faqHead: e.target.value })}
          label="FAQ Head"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={FAQDetailData.faqSubHead}
          onChange={(e) => setFAQDetailData({ ...FAQDetailData, faqSubHead: e.target.value })}
          label="FAQ Sub Head"
          type="text"
          margin="normal"
        />
        {/* <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Choose Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(FAQDetailData.bannerColor);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setFAQDetailData({ ...FAQDetailData, bannerColor: background2 });
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: FAQDetailData.bannerColor,
                }}
              />
            </Button>
          )}
        </div> */}
        {/* <div className={classes.formFields}>
      <img src={FAQDetailData?.img || avatar9} width='200px' height='200px' alt="preview" />
        <div>Choose Image<br/><FileBase type="file" multiple={false} onDone={({ base64 }) => setFAQDetailData({ ...FAQDetailData, img: base64 })} /></div>
      </div> */}

        {/* <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {FAQDetailData?.bannerImg ? (
            <img src={FAQDetailData?.bannerImg} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setFAQDetailData({ ...FAQDetailData, bannerImg: base64 })}
            />
          </div>
        </div> */}

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          FAQ Details Math
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmitMath}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        {/* <TextField
          className={classes.formFields}
          value={FAQDetailData.bannerText}
          onChange={(e) => setFAQDetailData({ ...FAQDetailData, bannerText: e.target.value })}
          label="Banner Text"
          type="text"
          margin="normal"
        /> */}
        <TextField
          className={classes.formFields}
          value={FAQDetailDataMath.faqHeadMath}
          onChange={(e) => setFAQDetailDatamMath({ ...FAQDetailDataMath, faqHeadMath: e.target.value })}
          label="FAQ Head"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={FAQDetailDataMath.faqSubHeadMath}
          onChange={(e) => setFAQDetailDatamMath({ ...FAQDetailDataMath, faqSubHeadMath: e.target.value })}
          label="FAQ Sub Head"
          type="text"
          margin="normal"
        />
        {/* <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Choose Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(FAQDetailData.bannerColor);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setFAQDetailData({ ...FAQDetailData, bannerColor: background2 });
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: FAQDetailData.bannerColor,
                }}
              />
            </Button>
          )}
        </div> */}
        {/* <div className={classes.formFields}>
      <img src={FAQDetailData?.img || avatar9} width='200px' height='200px' alt="preview" />
        <div>Choose Image<br/><FileBase type="file" multiple={false} onDone={({ base64 }) => setFAQDetailData({ ...FAQDetailData, img: base64 })} /></div>
      </div> */}

        {/* <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {FAQDetailData?.bannerImg ? (
            <img src={FAQDetailData?.bannerImg} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setFAQDetailData({ ...FAQDetailData, bannerImg: base64 })}
            />
          </div>
        </div> */}

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>

    </Container>
  );
}

export default NewFAQDetail;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
