import { Navigate, useRoutes } from 'react-router-dom';
import Cookies from 'universal-cookie';

import UserPage from './pages/users/UserPage';
import TeacherPage from './pages/teacher/TeacherPage';
import ClassPage from './pages/classes/ClassPage';
import TimetablePage from './pages/timetable/TimetablePage';
import TestimonialPage from './pages/testimonial/TestimonialPage';
import CoursePage from './pages/course/CoursePage';
import ContactPage from './pages/contact/ContactPage';
import ResourcePage from './pages/resource/ResourcePage';
import ChildProfilePage from './pages/child/ChildProfilePage';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Dashboard from './components/Dashboard';
import NewClasses from './pages/classes/NewClass';
import NewCourse from './pages/course/NewCourse';
import NewTeacher from './pages/teacher/NewTeacher';
import NewTimetable from './pages/timetable/NewTimetable';
import NewResource from './pages/resource/NewResource';
import NewTestimonial from './pages/testimonial/NewTestimonial';
import UpdateClasses from './pages/classes/UpdateClass';
import UpdateCourse from './pages/course/UpdateCourse';
import UpdateTeacher from './pages/teacher/UpdateTeacher';
import UpdateTimetable from './pages/timetable/UpdateTimetable';
import UpdateResource from './pages/resource/UpdateResource';
import UpdateTestimonial from './pages/testimonial/UpdateTestimonial';
import UpdateUser from './pages/users/UpdateUser';
import RegisterPage from './pages/RegisterPage';
import ChildDetails from './pages/child/ChildDetails';
import ContactDetails from './pages/contact/ContactDetails';
import AddNewUser from './pages/users/AddNewUser';
import EnquiryPage from './pages/enquiry/EnquiryPage';
import NewEnquiry from './pages/enquiry/NewEnquiry';
import UpdateEnquiry from './pages/enquiry/UpdateEnquiry';
import ChangePassword from './pages/ChangePassword';
import Settings from './pages/settings/Settings';
import NewAboutUs from './pages/aboutus/NewAboutUs';
import FAQPage from './pages/FAQ/FAQPage';
import NewFAQ from './pages/FAQ/NewFAQ';
import NewFAQDetail from './pages/FAQ/NewFAQDetail';
import UpdateFAQ from './pages/FAQ/UpdateFAQ';
import JoinTeam from './pages/joinTeam/JoinTeamPage';
import GalleryPage from './pages/gallery/GalleryPage';
import NewGallery from './pages/gallery/NewGallery';
import UpdateGallery from './pages/gallery/UpdateGallery';
import Subscriber from './pages/subscriber/subscriber';
import AboutUsPage from './pages/aboutus/AboutUsPage';
import UpadateAboutUs from './pages/aboutus/UpdateAboutUs';

// ----------------------------------------------------------------------

const cookies = new Cookies();
const token = cookies.get('TOKEN');

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: token ? <Dashboard /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/class" />, index: true },
        {
          path: '/user',
          element: <UserPage />,
        },
        {
          path: '/user/newUser',
          element: <AddNewUser />,
        },
        {
          path: '/user/updateUser/:id',
          element: <UpdateUser />,
        },
        {
          path: '/changePassword',
          element: <ChangePassword />,
        },
        {
          path: '/enquiry',
          element: <EnquiryPage />,
        },
        {
          path: '/enquiry/newEnquiry',
          element: <NewEnquiry />,
        },
        {
          path: '/enquiry/updateEnquiry/:id',
          element: <UpdateEnquiry />,
        },
        {
          path: '/teacher',
          element: <TeacherPage />,
        },
        {
          path: '/teacher/newTeacher',
          element: <NewTeacher />,
        },
        {
          path: '/teacher/updateTeacher/:id',
          element: <UpdateTeacher />,
        },
        {
          path: '/class',
          element: <ClassPage />,
        },
        {
          path: '/class/newClass',
          element: <NewClasses />,
        },

        {
          path: '/class/updateClass/:id',
          element: <UpdateClasses />,
        },
        {
          path: '/course',
          element: <CoursePage />,
        },
        {
          path: '/course/newCourse',
          element: <NewCourse />,
        },
        {
          path: '/course/updateCourse/:id',
          element: <UpdateCourse />,
        },
        {
          path: '/contact',
          element: <ContactPage />,
        },
        {
          path: '/contact/details/:id',
          element: <ContactDetails />,
        },
        {
          path: '/childprofile',
          element: <ChildProfilePage />,
        },
        {
          path: '/childprofile/details/:id',
          element: <ChildDetails />,
        },
        {
          path: '/join-team',
          element: <JoinTeam />,
        },
        {
          path: '/subscriber',
          element: <Subscriber />,
        },
        {
          path: '/resource',
          element: <ResourcePage />,
        },
        {
          path: '/resource/newResource',
          element: <NewResource />,
        },
        {
          path: '/resource/updateResource/:id',
          element: <UpdateResource />,
        },
        {
          path: '/timetable',
          element: <TimetablePage />,
        },
        {
          path: '/timetable/newTimetable',
          element: <NewTimetable />,
        },
        {
          path: '/timetable/updateTimetable/:id',
          element: <UpdateTimetable />,
        },
        {
          path: '/testimonial',
          element: <TestimonialPage />,
        },
        {
          path: '/testimonial/newTestimonial',
          element: <NewTestimonial />,
        },
        {
          path: '/testimonial/updateTestimonial/:id',
          element: <UpdateTestimonial />,
        },

        {
          path: '/settings',
          element: <Settings />,
        },
        {
          path: '/about-us/new-bout-us',
          element: <NewAboutUs />,
        },
        {
          path: '/about-us',
          element: <AboutUsPage />,
        },
        {
          path: '/about-us/about-us-update/:id',
          element: <UpadateAboutUs />,
        },
        {
          path: '/FAQ',
          element: <FAQPage />,
        },
        {
          path: '/FAQ/newFAQ',
          element: <NewFAQ />,
        },
        {
          path: '/FAQ/updateFAQ/:id',
          element: <UpdateFAQ />,
        },
        {
          path: '/FAQ/newFAQDetail',
          element: <NewFAQDetail />,
        },
        {
          path: '/gallery',
          element: <GalleryPage />,
        },
        {
          path: '/gallery/new-gallery',
          element: <NewGallery />,
        },

        {
          path: '/gallery/update-gallery/:id',
          element: <UpdateGallery />,
        },
      ],
    },

    {
      path: '/login',
      element: token ? <Navigate to="/class" /> : <LoginPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      element: <Dashboard />,
      children: [
        { element: <Navigate to="/class" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },

    // {
    //   children: [
    //     { element: <Navigate to="/user" />, index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
