import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, ClickAwayListener, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function UpdateFAQ() {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [subjectStatus, setSubjectStatus] = useState("english")

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const updateTesti = async (question, answer, subjectStatus,) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/FAQ/${id}`,
        { question, answer, subjectStatus },
        { question, answer, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/FAQ');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/FAQ/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setQuestion(result?.question);
        setAnswer(result?.answer);
        setSubjectStatus(result?.subjectStatus);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (question === '') {
      toast.error('Question is required');
      return;
    }
    if (answer === '') {
      toast.error('Answer is required');
      return;
    }
    updateTesti(question, answer, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/FAQ');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New FAQ
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          label="Question"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          label="Answer"
          type="text"
          margin="normal"
        />

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateFAQ;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
