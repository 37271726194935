import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, ClickAwayListener, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import InputAdornment from '@mui/material/InputAdornment';

const cookies = new Cookies();

function UpdateClasses() {
  const { id } = useParams();
  const classes = useStyles();
  const [bookinglink, setBookinglink] = useState('');
  const [subjectStatus, setSubjectStatus] = useState("english")
  const [firstTermClass, setFirstTermClass] = useState('');
  const [termPrice, setTermPrice] = useState('');

  const [title, setTitle] = useState('');
  const [classHours, setclassHours] = useState([{ days: '', time: '' }]);
  const [pricing, setPricing] = useState([{ classname: '', price: '' }]);
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');
  const [background, setBackground] = useState('');
  const [background2, setBackground2] = useState('');
  const [img, setImg] = useState('');

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleclassHoursChange = (i, e) => {
    const newHourValues = [...classHours];
    newHourValues[i][e.target.name] = e.target.value;
    setclassHours(newHourValues);
  };

  const handlePriceChange = (i, e) => {
    const newPricingValues = [...pricing];
    newPricingValues[i][e.target.name] = e.target.value;
    setPricing(newPricingValues);
  };

  const addclassHoursFields = () => {
    setclassHours([...classHours, { days: '', time: '' }]);
  };

  const addPricingFields = () => {
    setPricing([...pricing, { classname: '', price: '' }]);
  };

  const removeclassHoursFields = (i) => {
    const newHourValues = [...classHours];
    newHourValues.splice(i, 1);
    setclassHours(newHourValues);
  };

  const removePricingFields = (i) => {
    const newPricingValues = [...pricing];
    newPricingValues.splice(i, 1);
    setPricing(newPricingValues);
  };

  const updateClass = async (title, classHours, pricing, description, details, background, bookinglink, firstTermClass, termPrice, img, subjectStatus) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/classes/${id}`,
        { title, classHours, pricing, description, details, background, bookinglink, firstTermClass, termPrice, img, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/class');
      })
      .catch((err) => console.log('err-', err));
    showLoader(false);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
        setImg(base64data);
      };
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/classes/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setTitle(result?.title);
        setclassHours(result?.classHours);
        setPricing(result?.pricing);
        setDescription(result?.description);
        setDetails(result?.details);
        setBackground(result?.background);
        setBookinglink(result?.bookinglink);
        setFirstTermClass(result?.firstTermClass);
        setTermPrice(result?.termPrice);
        setSubjectStatus(result?.subjectStatus);
        // setImg(result?.img?.url);
        getBase64FromUrl(result?.img?.url);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    /* validate first */
    if (title.length === 0) {
      toast.error('Title is required');
      return;
    }
    if (classHours[0].days === '' || classHours[0].time === '') {
      toast.error('Class hours is required');
      return;
    }
    if (pricing[0].days === '' || pricing[0].time === '') {
      toast.error('pricing is required');
      return;
    }
    if (description.length === 0) {
      toast.error('Front description is required');
      return;
    }
    if (details.length === 0) {
      toast.error('Description is required');
      return;
    }
    if (img.length === 0) {
      toast.error('Image is required');
      return;
    }
    updateClass(title, classHours, pricing, description, details, background, bookinglink, firstTermClass, termPrice, img, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/class');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Class
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          label="Title"
          type="text"
          margin="normal"
          style={{ width: '82%' }}
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={bookinglink}
          onChange={(e) => setBookinglink(e.target.value)}
          label="Booking Link"
          type="text"
          margin="normal"
        />
        <>
          <TextField
            className={classes.formFields}
            onChange={(e) => setFirstTermClass(e.target.value)}
            value={firstTermClass}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            label="First Term for Tution class B"
            type="text"
            margin="normal"
          // style={{ width: '82%' }}
          />
          <TextField
            className={classes.formFields}
            sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
            value={termPrice}
            onChange={(e) => setTermPrice(e.target.value)}
            label="Price"
            InputProps={{
              startAdornment: <InputAdornment position="start">£</InputAdornment>,
            }}
            type="text"
            margin="normal"
          />
        </>
        <>
          {classHours?.map((element, index) => (
            <>
              <TextField
                // className={classes.formFields}
                onChange={(e) => handleclassHoursChange(index, e)}
                value={element?.days}
                name="days"
                label="Day"
                type="text"
                margin="normal"
                style={{ width: '40%' }}
              />
              <TextField
                // className={classes.formFields}
                onChange={(e) => handleclassHoursChange(index, e)}
                value={element?.time || ''}
                label="Time"
                name="time"
                type="text"
                margin="normal"
                style={{ width: '40%' }}
              />
              {index ? (
                <Button variant="outlined" color="error" onClick={() => removeclassHoursFields(index)}>
                  <RemoveRoundedIcon />
                </Button>
              ) : null}
            </>
          ))}
          <br />
          <Button variant="contained" onClick={() => addclassHoursFields()}>
            <AddRoundedIcon />
          </Button>
        </>
        {pricing?.map((element, index) => (
          <>
            <TextField
              // className={classes.formFields}
              onChange={(e) => handlePriceChange(index, e)}
              value={element.classname || ''}
              label="Class Type"
              type="text"
              name="classname"
              margin="normal"
              style={{ width: '40%' }}
            />
            <TextField
              // className={classes.formFields}
              onChange={(e) => handlePriceChange(index, e)}
              value={element.price || ''}
              label="Price"
              name="price"
              type="text"
              margin="normal"
              style={{ width: '40%' }}
            />
            {index ? (
              <Button variant="outlined" color="error" onClick={() => removePricingFields(index)}>
                <RemoveRoundedIcon />
              </Button>
            ) : null}
          </>
        ))}

        <Button variant="contained" onClick={() => addPricingFields()}>
          <AddRoundedIcon />
        </Button>
        <br />
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Front Description</p>
          {description !== '' ? <QuillEditor value={description} setDesc={setDescription} /> : ''}
        </div>
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Description</p>
          {details !== '' ? <QuillEditor value={details} setDesc={setDetails} /> : ''}
        </div>
        <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Change Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(background);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setBackground(background2);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: background }} />
            </Button>
          )}
        </div>

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setImg(base64)}
            />
          </div>
        </div>

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateClasses;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
