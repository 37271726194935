import { useQuill } from 'react-quilljs'
import './quill.snow.css';
import { useEffect } from 'react';

const QuillEditor = ({setDesc, value}) => {
  const { quill, quillRef} = useQuill()
  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setDesc(quillRef.current.firstChild.innerHTML)
      })
      quill.clipboard.dangerouslyPasteHTML(value)
    }
  },[quill])
  return (
    <div >
      <div value={value} ref={quillRef}/>
    </div>
  )
}

export default QuillEditor