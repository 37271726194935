import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import QuillEditor from 'src/utils/QuillEditor';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function UpadateAboutUs() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [aboutData, setAboutData] = useState({
    aboutImg: '',
    id: id,
    heading: '',
    subHeading: '',
    subjectStatus: "english"
    // paragraph: '',
  });
  const [paragraph, setParagraph] = useState('');
  const [paragraphCondition, setParagraphCondition] = useState(false);

  const createAboutData = async () => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/about-us`,
        { ...aboutData, paragraph: paragraph },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/about-us');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (String(aboutData?.aboutImg)?.length === 0) {
      toast.error('About Img is required');
      return;
    }
    if (String(aboutData?.heading)?.length === 0) {
      toast.error('Heading is required');
      return;
    }
    if (String(paragraph)?.length === 0) {
      toast.error('Paragraph is required');
      return;
    }

    createAboutData();
  };

  const back = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const getBase64FromUrl = async (url) => {
    return new Promise(async (resolve) => {
      const data = await fetch(url);
      const blob = await data.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        return resolve(base64data);
      };
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/about-us/${id}`, {
      method: 'GET',
    })
      .then(async (response) => {
        let result = await response.json();

        let data = {
          heading: result?.heading ?? '',
          id: result?.id ?? '',
          subHeading: result?.subHeading ?? '',
          subHeading: result?.subHeading ?? '',
          subjectStatus: result?.subjectStatus ?? '',
          // paragraph: result.paragraph,
        };
        if (result?.aboutImg?.url) {
          data.aboutImg = await getBase64FromUrl(result?.aboutImg?.url);
        } else {
          data.aboutImg = '';
        }
        setParagraph(result?.paragraph ?? '');
        setAboutData(data);
        setParagraphCondition(true);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          About Us
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <Grid container spacing={2} md={12}>
          <Grid item md={6} style={{ padding: 0 }}>
            About Image
            <div
              className={classes.formFields}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px dashed #1475cf',
                borderRadius: '10px',
                height: '260px',
                width: '380px',
                marginInline: 0,
              }}
            >
              {aboutData?.aboutImg ? (
                <img src={aboutData?.aboutImg} width="200px" height="200px" alt="preview" />
              ) : (
                <MdCloudUpload color="#1475cf" size={60} />
              )}
              <div>
                <FileBase
                  type="file"
                  id="images"
                  accept="image/*"
                  required
                  multiple={false}
                  onDone={({ base64 }) => setAboutData({ ...aboutData, aboutImg: base64 })}
                />
              </div>
            </div>
          </Grid>
        </Grid>


        <TextField
          className={classes.formFields}
          value={aboutData.heading}
          onChange={(e) => setAboutData({ ...aboutData, heading: e.target.value })}
          label="Heading"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={aboutData.subHeading}
          onChange={(e) => setAboutData({ ...aboutData, subHeading: e.target.value })}
          label="Sub Heading"
          type="text"
          margin="normal"
        />
        {/* <TextField
          className={classes.formFields}
          value={paragraph}
          onChange={(e) => setParagraph(e.target.value)}
          label="Paragraph"
          type="text"
          margin="normal"
          multiline
          rows={6}
        /> */}
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Paragraph</p>
          {paragraphCondition && <QuillEditor setDesc={setParagraph} value={paragraph} />}
        </div>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpadateAboutUs;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
