import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function UpdateGallery() {
  const { id } = useParams();
  const classes = useStyles();
  const [subjectStatus, setSubjectStatus] = useState("english")
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  // const [background, setBackground] = useState('');
  // const [background2, setBackground2] = useState('');
  const [img, setImg] = useState('');

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();








  const updateClass = async (title, details, img, subjectStatus) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/gallery/${id}`,
        { title, details, img, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/gallery');
      })
      .catch((err) => console.log('err-', err));
    showLoader(false);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
        setImg(base64data);
      };
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/gallery/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setTitle(result?.title);
        setDetails(result?.details);
        // setBackground(result?.background);
        // setImg(result?.img?.url);
        getBase64FromUrl(result?.img?.url);
        setSubjectStatus(result?.subjectStatus);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    /* validate first */
    if (title.length === 0) {
      toast.error('Title is required');
      return;
    }
    if (details.length === 0) {
      toast.error('Description is required');
      return;
    }
    if (img.length === 0) {
      toast.error('Image is required');
      return;
    }
    updateClass(title, details, img, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/class');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Gallery
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          label="Title"
          type="text"
          margin="normal"
          style={{ width: '82%' }}
        />



        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Description</p>
          {details !== '' ? <QuillEditor value={details} setDesc={setDetails} /> : ''}
        </div>
        {/* <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Change Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(background);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setBackground(background2);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: background }} />
            </Button>
          )}
        </div> */}

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setImg(base64)}
            />
          </div>
        </div>

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateGallery;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
