import { toast } from 'react-toastify';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();
function UpdateUser() {
  const classes = useStyles();
  const { id } = useParams();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [img, setImage] = useState('');
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  const updateUserStatus = async (userName, email, img, status) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/auth/${id}`,
        { userName, email, img, status },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/user');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/auth/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setUserName(result?.userName);
        setEmail(result?.email);
        setImage(result?.img);
        setStatus(result?.status);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userName === '') {
      toast.error('Username is required');
      return;
    }
    if (email === '') {
      toast.error('Email is required');
      return;
    }

    updateUserStatus(userName, email, img, status);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update User Status
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          label="Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
          margin="normal"
        />
        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              value={img}
              onDone={({ base64 }) => setImage({ base64 })}
            />
          </div>
        </div>

        <FormControl className={classes.formFields}>
          <FormLabel id="demo-radio-buttons-group-label" sx={{ fontFamily: 'Open Sans' }}>
            Status
          </FormLabel>
          <RadioGroup
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Inactive"
            name="radio-buttons-group"
          >
            <FormControlLabel value="active" control={<Radio />} label="Active" />
            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
          </RadioGroup>
        </FormControl>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
