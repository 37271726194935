import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import FileBase from 'react-file-base64';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function NewCourse() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [cost, setCost] = useState('');
  const [location, setLocation] = useState('');
  const [bookinglink, setBookinglink] = useState('');
  const [subjectStatus, setSubjectStatus] = useState(localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english")

  const [img, setImg] = useState('');

  const createCourse = async (title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus) => {
    showLoader(true);
    await axios
      .post(
        `${API_URL}/api/courses`,
        { title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        navigate('/course');
      })
      .catch((err) => console.log(err));

    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* validate first */
    if (title.length === 0) {
      toast.error('Title is required');
      return;
    }

    if (date.length === 0) {
      toast.error('Date is required');
      return;
    }

    if (time.length === 0) {
      toast.error('Time is required');
      return;
    }

    if (cost.length === 0) {
      toast.error('Cost is required');
      return;
    }

    if (description.length === 0) {
      toast.error('Front description is required');
      return;
    }
    if (longDescription.length === 0) {
      toast.error('Description is required');
      return;
    }
    if (img.length === 0) {
      toast.error('Image is required');
      return;
    }
    createCourse(title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/course');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Course
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label="Title"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          label="Date"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          label="Time"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          label="Cost"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          label="Location"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          sx={{ fontFamily: 'Open Sans', fontWeight: '400' }}
          value={bookinglink}
          onChange={(e) => setBookinglink(e.target.value)}
          label="Booking Link"
          type="text"
          margin="normal"
        />
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Front Description</p>
          <QuillEditor value={description} setDesc={setDescription} />
        </div>
        <div className={classes.textEditor}>
          <p style={{ margin: '5px 0px', fontFamily: 'Open Sans' }}>Description</p>
          <QuillEditor value={longDescription} setDesc={setLongDescription} />
        </div>

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => {
                console.log(base64);
                setImg(base64);
              }}
            />
          </div>
        </div>

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewCourse;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
