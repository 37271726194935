import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  Tooltip,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
// components
import Iconify from '../../components/iconify';
// sections
import Select from '@mui/material/Select';

// mock
import { API_URL } from '../../api';
import {
  LoadPanel,
  SearchPanel,
  Paging,
  Pager,
  FilterPanel,
  FilterBuilderPopup,
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Scrolling,
  Editing,
  Summary,
  GroupItem,
  TotalItem,
  ValueFormat,
  Export,
  Button as DevBtn,
  Lookup,
  RowDragging,
} from 'devextreme-react/data-grid';
import Cookies from 'universal-cookie';

import { toast } from 'react-toastify';
import { DeleteModal } from './DeleteModal';
// ----------------------------------------------------------------------
const cookies = new Cookies();

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_class) => _class.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GalleryPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [clas, setClas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentId, setCurrentId] = useState(null);
  const [plus, setPlus] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [subjectStatus, setSubjectStatus] = useState("english")

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  // const deleteClass = (id) => {
  //   axios.delete(`${API_URL}/api/classes/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/classes/`)
  //       .then((res) => setClas(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    let subject = localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english"
    getListGallery(subject)
  }, [plus]);

  const getListGallery = (parm) => {
    axios
      .get(`${API_URL}/api/gallery?subject=${parm}`)
      .then((res) => {
        setClas([...res.data]);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }










  const createNew = (e) => {
    e.preventDefault();
    navigate('/gallery/new-gallery');
  };

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...clas];

    const toIndex = newData.findIndex((item) => item._id === visibleRows[e.toIndex].data._id);
    const fromIndex = newData.findIndex((item) => item._id === e.itemData._id);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    // await changePriority(newData.map((i) => i._id));
    setClas(newData);
  };

  const changePriority = async (data) => {
    return axios
      .post(
        `${API_URL}/api/classes/priority`,
        { data: data },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then(() => {
        // getAllCourseCategoryData();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Something went wrong. Please try again later!');
      });
  };
  return (
    <>
      <Helmet>
        <title> Gallery | English Teacher </title>
      </Helmet>

      <Container>
        <Stack className="stacky">
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Gallery
          </Typography>
          <div>
            <Button
              onClick={(e) => createNew(e)}
              variant="contained"
              style={{ padding: '8px 22px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add a New Gallery
            </Button>

          </div>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          <DataGrid dataSource={clas} showBorders={true} autoExpandAll={true} columnAutoWidth={true} keyExpr="_id">
            <LoadPanel enabled={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={{ of: window, at: 'top', my: 'top', offset: { y: 10 } }} />
            <Scrolling mode="standard" />
            <Editing mode="row" allowDeleting={true} allowUpdating={true} />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={[10, 25, 50, 100]}
              showPageSizeSelector={true}
              displayMode="full"
              showInfo={true}
              showNavigationButtons={true}
            />
            <RowDragging allowReordering={true} onReorder={onReorder} showDragIcons={true} />
            <Column type="buttons" caption="Action" width={200}>
              <DevBtn onClick={(e) => navigate(`/gallery/update-gallery/${e.row?.data?._id}`)} icon="edit">
                <Button className="btn">
                  <Tooltip title="Edit" placement="top-start" arrow>
                    <Iconify icon="mdi:pencil" />
                  </Tooltip>
                </Button>
              </DevBtn>
              {/* <DevBtn onClick={(e) => deleteClass(e.row?.data?._id)} icon="info">
                <Button className="btn">
                  <Iconify icon="mdi:trash-outline" />
                </Button>
              </DevBtn> */}
              <DevBtn
                icon="delete"
                onClick={(e) => {
                  handleDeleteModal(e?.row?.data?._id);
                }}
              >
                <Button className="btn">
                  <Tooltip title="Delete" placement="top-start" arrow>
                    <Iconify icon="mdi:trash-outline" />
                  </Tooltip>
                </Button>
              </DevBtn>
            </Column>

            <Column dataField="title" caption="Title" />
            {/* <Column dataField={ReactHtmlParser("details")} caption="Description" /> */}

          </DataGrid>

        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setClas={setClas}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}
