import { toast } from 'react-toastify';
import { Alert, Box, Button, ButtonGroup, Container, Modal, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();
function AddNewUser() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClick = async (e) => {
    e.preventDefault();
    if (userName === '') {
      toast.error('Username is required');
      return;
    }
    if (email === '') {
      toast.error('Email is required');
      return;
    }
    if (password === '') {
      toast.error('Password is required');
      return;
    }

    showLoader(true);

    await axios
      .post(
        `${API_URL}/api/auth/register`,
        { userName, email, password },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/user');
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response?.data?.message);
        setOpen(true);
      });
    showLoader(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FDEDED',
    boxShadow: 24,
    borderRadius: '5px',
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/user');
  };
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New User
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleClick}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          label="UserName"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type="text"
          margin="normal"
        />
        {open ? (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Alert severity="error">{error}</Alert>
            </Box>
          </Modal>
        ) : (
          ''
        )}
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Create
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default AddNewUser;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
