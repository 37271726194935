import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ContactListHead, ContactListToolbar } from '../../sections/@dashboard/contact';
import { API_URL } from '../../api';
import { DeleteModal } from './DeleteModal';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  // { id: 'howDidYou', label: 'How did you hear about us?', alignRight: false },
  // { id: 'enquiry', label: 'Enquiry', alignRight: false },
  { id: 'enquiryAbout', label: 'Enquiry About', alignRight: false },
  { id: '', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_contact) => _contact?.name?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function ContactPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState();
  const [plus, setPlus] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };
  const navigate = useNavigate();

  // const deleteContact = (id) => {
  //   axios.delete(`${API_URL}/api/messages/${id}`).then(
  //     () => setPlus(plus + 1),
  //     axios
  //       .get(`${API_URL}/api/messages/`)
  //       .then((res) => setContact(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/messages/`)
      .then((res) => {
        setContact(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [plus]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contact?.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contact?.length) : 0;

  const filteredContacts = applySortFilter(contact, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredContacts?.length && !!filterName;

  function detailsPage(id) {
    navigate(`/contact/details/${id}`);
  }

  const enquiryList = (e) => {
    e.preventDefault();
    navigate('/enquiry');
  };

  return (
    <>
      <Helmet>
        <title> Child Profile Form | English Teacher </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Contact Form
          </Typography>
          <Button
            onClick={(e) => enquiryList(e)}
            variant="contained"
            style={{ padding: '8px 22px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Enquiry List
          </Button>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          <ContactListToolbar
            selected={selected}
            setContact={setContact}
            numSelected={selected?.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ContactListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={contact?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {loading ? (
                  <Box>
                    <CircularProgress sx={{ display: 'flex', justifyContent: 'center' }} />
                  </Box>
                ) : (
                  <TableBody>
                    {filteredContacts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => {
                      const { _id, name, email, phone, aboutEnquiry } = row;
                      const selectedContact = selected?.indexOf(_id) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedContact}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedContact}
                              onChange={(event) => handleClick(event, _id)}
                              sx={{ mr: 4 }}
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '600' }}
                            onClick={() => detailsPage(_id)}
                          >
                            {name}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '400' }}
                            onClick={() => detailsPage(_id)}
                          >
                            {email}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '400' }}
                            onClick={() => detailsPage(_id)}
                          >
                            {phone}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{ cursor: 'pointer', fontFamily: 'Open Sans', fontWeight: '400' }}
                            onClick={() => detailsPage(_id)}
                          >
                            {aboutEnquiry?.slice(0, 15)}
                          </TableCell>

                          <TableCell align="left">
                            <MenuItem onClick={() => handleDeleteModal(_id)} sx={{ color: 'error.main' }}>
                              <Iconify icon={'eva:trash-2-outline'} />
                            </MenuItem>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ fontFamily: 'Open Sans' }}>
                            Not found
                          </Typography>

                          <Typography variant="body2" sx={{ fontFamily: 'Open Sans' }}>
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={contact?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setContact={setContact}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}
