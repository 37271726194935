// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {

      styleOverrides: {
        head: {
          fontFamily:'Open Sans',
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          
        },
      },
    },
  };
}
