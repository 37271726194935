import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
const cookies = new Cookies();

function UpdateEnquiry() {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();

  const [enquiry, setEnquiry] = useState('');

  const updateEnq = async (enquiry) => {
    if (enquiry === '') {
      toast.error('Enquiry is required');
      return;
    }
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/enquiry/${id}`,
        { enquiry },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/enquiry');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  useEffect(() => {
    fetch(`${API_URL}/api/enquiry/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setEnquiry(result?.enquiry);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEnq(enquiry);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/enquiry');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom style={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Edit Enquiry
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >
        <TextField
          className={classes.formFields}
          value={enquiry}
          onChange={(e) => setEnquiry(e.target.value)}
          label="Enquiry"
          type="text"
          margin="normal"
        />

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateEnquiry;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
