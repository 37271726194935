import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function UpdateCourse() {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [subjectStatus, setSubjectStatus] = useState("english")

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [cost, setCost] = useState('');
  const [location, setLocation] = useState('');
  const [bookinglink, setBookinglink] = useState('');
  const [img, setImg] = useState('');

  const updateCourse = async (title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/courses/${id}`,
        { title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        navigate('/course');
      })
      .catch((err) => console.log(err));
    showLoader(false);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
        setImg(base64data);
      };
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/courses/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setTitle(result?.title);
        setDescription(result?.description);
        setLongDescription(result?.longDescription);
        setDate(result?.date);
        setTime(result?.time);
        setCost(result?.cost);
        setLocation(result?.location);
        setBookinglink(result?.bookinglink);
        setSubjectStatus(result?.subjectStatus);
        setDate(result?.date);
        // setImg(result?.img)
        getBase64FromUrl(result?.img?.url);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    /* validate first */
    if (title.length === 0) {
      toast.error('Title is required');
      return;
    }

    if (date.length === 0) {
      toast.error('Date is required');
      return;
    }

    if (time.length === 0) {
      toast.error('Time is required');
      return;
    }

    if (cost.length === 0) {
      toast.error('Cost is required');
      return;
    }

    if (description.length === 0) {
      toast.error('Front description is required');
      return;
    }
    if (longDescription.length === 0) {
      toast.error('Description is required');
      return;
    }
    if (img.length === 0) {
      toast.error('Image is required');
      return;
    }
    updateCourse(title, description, longDescription, date, time, cost, location, bookinglink, img, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/course');
  };
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Course
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          label="Title"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={date}
          onChange={(e) => setDate(e.target.value)}
          label="Date"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={time}
          onChange={(e) => setTime(e.target.value)}
          label="Time"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          label="Cost"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          label="Location"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          value={bookinglink}
          onChange={(e) => setBookinglink(e.target.value)}
          label="Booking Link"
          type="text"
          margin="normal"
        />
        <div className={classes.textEditor}>
          Description
          {description !== '' ? <QuillEditor value={description} setDesc={setDescription} /> : ''}
        </div>
        <div className={classes.textEditor}>
          Long Description
          {longDescription !== '' ? <QuillEditor value={longDescription} setDesc={setLongDescription} /> : ''}
        </div>

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setImg(base64)}
            />
          </div>
        </div>

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateCourse;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
