import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import FileBase from 'react-file-base64';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import QuillEditor from '../../utils/QuillEditor';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function UpdateTeacher() {
  const { id } = useParams();
  const classes = useStyles();
  const [name, setName] = useState('');
  const [frontDescription, setFrontDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [subjectStatus, setSubjectStatus] = useState("english")
  const [img, setImg] = useState('');

  const navigate = useNavigate();

  const updateTeacher = async (name, frontDescription, longDescription, img, subjectStatus) => {
    showLoader(true);
    await axios
      .patch(
        `${API_URL}/api/teachers/${id}`,
        { name, frontDescription, longDescription, img, subjectStatus },
        {
          headers: {
            Authorization: cookies.get('TOKEN'),
          },
        }
      )
      .then((res) => {
        toast.success(res.data?.message || 'Data updated successfully.');
        navigate('/teacher');
      })
      .catch((err) => console.log('err-', err));
    showLoader(false);
  };

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
        setImg(base64data);
      };
    });
  };

  useEffect(() => {
    fetch(`${API_URL}/api/teachers/${id}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((result) => {
        setName(result?.name);
        setFrontDescription(result?.frontDescription);
        setLongDescription(result?.longDescription);
        setSubjectStatus(result?.subjectStatus);
        // setImg(result?.img);
        getBase64FromUrl(result?.img?.url);
      })
      .catch((error) => console.log('error', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === '') {
      toast.error('Name is required');
      return;
    }
    if (frontDescription === '') {
      toast.error('Front description is required');
      return;
    }
    if (longDescription === '') {
      toast.error('Long description is required');
      return;
    }
    if (img === '') {
      toast.error('Image is required');
      return;
    }
    updateTeacher(name, frontDescription, longDescription, img, subjectStatus);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/teacher');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          Update Teacher
        </Typography>
      </Stack>
      <Box
        component="form"
        onSubmit={handleSubmit}
        className={classes.form}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          onChange={(e) => setName(e.target.value)}
          value={name}
          label="Name"
          type="text"
          margin="normal"
        />
        <TextField
          className={classes.formFields}
          onChange={(e) => setFrontDescription(e.target.value)}
          value={frontDescription}
          label="Front Description"
          type="text"
          margin="normal"
        />
        <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Long Description
          {longDescription !== '' ? <QuillEditor value={longDescription} setDesc={setLongDescription} /> : ''}
        </div>

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {img ? (
            <img src={img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              value={img}
              onDone={({ base64 }) => setImg(base64)}
            />
          </div>
        </div>
        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default UpdateTeacher;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    formFieldsSelect: {
      flexBasis: '91%',
      margin: '10px',

      [theme.breakpoints.down('sm')]: {
        flexBasis: '91%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
