import { toast } from 'react-toastify';
import { Box, Button, ButtonGroup, ClickAwayListener, Container, Stack, TextField, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import axios from 'axios';
import { SketchPicker } from 'react-color';
import FileBase from 'react-file-base64';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MdCloudUpload } from 'react-icons/md';
import { API_URL } from '../../api';
import Cookies from 'universal-cookie';
import showLoader from 'src/components/loader';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const cookies = new Cookies();

function NewTestimonial() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [testi, setTesti] = useState({ title: '', background: '#FF5733', img: '', subjectStatus: localStorage.getItem("subject") !== null && localStorage.getItem("subject") !== undefined ? localStorage.getItem("subject") : "english" });
  const [background2, setBackground2] = useState('');
  const [open, setOpen] = useState(false);

  const createTesti = async (testi) => {
    showLoader(true);
    await axios
      .post(`${API_URL}/api/testimonial`, testi, {
        headers: {
          Authorization: cookies.get('TOKEN'),
        },
      })
      .then((res) => {
        toast.success(res.data?.message || 'Data added successfully.');
        navigate('/testimonial');
      })
      .catch((err) => console.log(err));

    showLoader(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (testi.title === '') {
      toast.error('Title is required');
      return;
    }
    if (testi.img === '') {
      toast.error('Image is required');
      return;
    }
    createTesti(testi);
  };

  const back = (e) => {
    e.preventDefault();
    navigate('/testimonial');
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
          New Testimonial
        </Typography>
      </Stack>
      <Box
        component="form"
        className={classes.form}
        onSubmit={handleSubmit}
        sx={{
          '& > :not(style)': { m: 1, width: '' },
        }}
      >

        <TextField
          className={classes.formFields}
          value={testi.title}
          onChange={(e) => setTesti({ ...testi, title: e.target.value })}
          label="Title"
          type="text"
          margin="normal"
        />
        <div className={classes.textEditor} style={{ fontFamily: 'Open Sans' }}>
          Choose Color
          {open ? (
            <div style={{ display: '-webkit-box' }}>
              <SketchPicker
                color={background2}
                onChangeComplete={(color) => {
                  setBackground2(color.hex);
                }}
              />
              <ButtonGroup
                className={classes.buttons}
                aria-label="outlined primary button group"
                style={{ marginLeft: '10px' }}
              >
                <Button
                  onClick={(e) => {
                    setOpen(false);
                    setBackground2(testi.background);
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    setOpen(false);
                    setTesti({ ...testi, background: background2 });
                  }}
                  style={{ fontFamily: 'Open Sans', minWidth: '90px' }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>
              <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: testi.background }} />
            </Button>
          )}
        </div>
        {/* <div className={classes.formFields}>
      <img src={testi?.img || avatar9} width='200px' height='200px' alt="preview" />
        <div>Choose Image<br/><FileBase type="file" multiple={false} onDone={({ base64 }) => setTesti({ ...testi, img: base64 })} /></div>
      </div> */}

        <div
          className={classes.formFields}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px dashed #1475cf',
            borderRadius: '10px',
            height: '260px',
            width: '380px',
          }}
        >
          {testi?.img ? (
            <img src={testi?.img} width="200px" height="200px" alt="preview" />
          ) : (
            <MdCloudUpload color="#1475cf" size={60} />
          )}
          <div>
            <FileBase
              type="file"
              id="images"
              accept="image/*"
              required
              multiple={false}
              onDone={({ base64 }) => setTesti({ ...testi, img: base64 })}
            />
          </div>
        </div>

        <ButtonGroup className={classes.buttons} aria-label="outlined primary button group">
          <Button onClick={(e) => back(e)} style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Back
          </Button>
          <Button variant="contained" type="submit" style={{ fontFamily: 'Open Sans', minWidth: '90px' }}>
            Publish
          </Button>
        </ButtonGroup>
      </Box>
    </Container>
  );
}

export default NewTestimonial;

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formFields: {
      flexBasis: '45%',
      margin: '10px',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    textEditor: {
      flexBasis: '91.5%',
      [theme.breakpoints.down('sm')]: {
        flexBasis: '90%',
      },
    },
    buttons: {
      flexBasis: '91.5%',
      justifyContent: 'flex-end',
    },
  })
);
