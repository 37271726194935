import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';
// @mui
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
// components
import axios from 'axios';

import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead } from '../../sections/@dashboard/user';
import { API_URL } from '../../api';
import { DeleteModal } from './DeleteModal';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true },
];

// ----------------------------------------------------------------------

export default function Users() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [users, setUsers] = useState('');
  const [currentId, setCurrentId] = useState(null);
  const [plus, setPlus] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const handleDeleteModal = (id) => {
    deleteId ? setDeleteId() : setDeleteId(id);
    setDeleteModal(!deleteModal);
  };

  const navigate = useNavigate();

  // const deleteUser = (id) => {
  //   axios.delete(`${API_URL}/api/auth/${id}`).then(
  //     () => setPlus(plus + 1),
  //     setOpen(false),
  //     axios
  //       .get(`${API_URL}/api/auth/`)
  //       .then((res) => setUsers(res.data))
  //       .catch((error) => console.log(error))
  //   );
  // };

  useEffect(() => {
    axios
      .get(`${API_URL}/api/auth/`)
      .then((res) => setUsers(res.data))
      .catch((error) => console.log(error));
  }, [plus]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleOpenMenu = (event, rowId) => {
    setOpen(event.currentTarget);
    setCurrentId(rowId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const createNew = (e) => {
    e.preventDefault();
    navigate('/user/newUser');
  };

  return (
    <>
      <Helmet>
        <title>English Teacher</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>
            Users
          </Typography>
          <Button
            onClick={(e) => createNew(e)}
            variant="contained"
            style={{ padding: '8px 22px', borderRadius: '35px', fontFamily: 'Open Sans', fontWeight: '500' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add a New User
          </Button>
        </Stack>

        <Card sx={{ borderRadius: '12px' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {Array.isArray(users) &&
                    users?.map((row) => {
                      const { _id, userName, img, email, status } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, _id)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={userName} src={img} />
                              <Typography
                                variant="subtitle2"
                                sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}
                                noWrap
                              >
                                {userName}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left" sx={{ fontFamily: 'Open Sans' }}>
                            {email}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              color={(status === 'inactive' && 'error') || 'success'}
                              sx={{ fontFamily: 'Open Sans' }}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, _id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                          <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <Link to={`/user/updateUser/${currentId}`}>
                              <MenuItem sx={{ fontFamily: 'Open Sans' }}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                            </Link>
                            <MenuItem
                              onClick={() => handleDeleteModal(currentId)}
                              sx={{ color: 'error.main', fontFamily: 'Open Sans' }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DeleteModal
          deleteModal={deleteModal}
          handleDeleteModal={handleDeleteModal}
          deleteId={deleteId}
          setUsers={setUsers}
          setPlus={setPlus}
          plus={plus}
        />
      </Container>
    </>
  );
}
